<template>
  <section class="bg-light">
    <div class="container">
      <h1>RUPE</h1>
      <h4>Registro Único de Proveedores del Estado</h4>
      <p>
        Es un instrumento del sistema de compras y contrataciones cuya
        dependencia funcional es en la Oficina Central de Contrataciones. Tiene
        por finalidad registrar a las personas humanas y jurídicas que deseen
        contratar con el Sector Público Provincial No Financiero, mediante la
        exigencia de requisitos de capacidad, solvencia, idoneidad y
        responsabilidad.
      </p>
      <!-- <ul>
      <li>Responsable: CP Gloria Posse</li>
      <li>Teléfono: 264 - 430 6830/66</li>
      <li>Correo: rupesj.proveedores@sanjuan.gob.ar</li>
    </ul>-->
    </div>
  </section>
  
  <!-- QUIERO SER PROVEEDOR SECTION -->
  <section class="container">
    <h2>Quiero ser proveedor</h2>
    <div class="row row-cols-2 row-cols-md-6 mt-2 g-4 justify-content-start text-center">
      <div class="col">
        <div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><!--v-if--><span
              class="material-symbols-outlined text-white ptn-i-2x">calendar_month</span></span>
          <div class="card-body">
            <h3 class="my-0 h6"><a href="https://turnos.sanjuan.gob.ar/" target="_self" class="stretched-link">Turnos
                Online</a></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <a href="https://compraspublicas.sanjuan.gob.ar/pdf/Inscripción_RUPE_01.pdf"
        target="_blank" class="text-ptn-color-gsj-brand">
        <h5>Trámite Presencial - Acceder al instructivo</h5>
      </a>
      <h5 class="text-ptn-color-gsj-brand"> <a
          href="https://compraspublicas.sanjuan.gob.ar/pdf/Tramite_no_presencial.pdf"
          target="_blank" class="text-ptn-color-gsj-brand"> Trámite No Presencial - Acceder al instructivo </a></h5>
    </div>
    <div class="container">
      <h4 class="py-4 mt-3 subtitle">Formularios de inscripción</h4>
      <div class="row justify-content-center row-cols-1 g-4">
        <div class="col">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                  </path>
                </svg><!--v-if-->
              </div><!--v-if-->
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/01-alta_proveedor_persona_humana_4.0.pdf"
                  target="_blank" class="stretched-link">Formulario de Inscripción Persona Humana</a></h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                  </path>
                </svg><!--v-if-->
              </div><!--v-if-->
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a
                  href="https://compraspublicas.sanjuan.gob.ar/pdf/03-alta_proveedor_persona_juridicas_4.0_Digital%20(1).pdf"
                  target="_blank" class="stretched-link">Formulario de Inscripción Persona Jurídica</a></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container py-0 pb-5">
    <div>
      <h2 class="py-4 mt-3">Si no sos proveedor y queres participar en una licitación pública, compulsa
        abreviada o subasta pública accede acá.</h2>
        <div class="row row-cols-2 row-cols-md-6 g-4 justify-content-start text-center">
          <div class="col">
            <div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x"
              viewBox="0 0 512 512">
                <path
                  d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                </path>
              </svg><!--v-if--></span>
              <div class="card-body">
              <h3 class="my-0 h6"><a href="https://certificadoprovisorio.sanjuan.gob.ar/" target="_self"
                class="stretched-link">Certificado Pre-Inscripción como Proveedor</a></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- QUIERO SER PROVEEDOR FIN -->

   <!-- SOY PROVEEDOR SECTION -->
  <section class="container">
    <h2>Soy proveedor</h2>
    <div class="row row-cols-2 row-cols-md-6 mt-2 g-4 justify-content-start text-center subsection">
      <div class="col">
        <div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x"
              viewBox="0 0 512 512">
              <path
                d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
              </path>
            </svg><!--v-if--></span>
          <div class="card-body">
            <h3 class="my-0 h6"><a href="?uid=CUMV-VIEW-ProveedorComponent" target="_self"
                class="stretched-link">Certificado de Proveedor - Descargá tu certificado</a></h3>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x"
              viewBox="0 0 512 512">
              <path
                d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
              </path>
            </svg><!--v-if--></span>
          <div class="card-body">
            <h3 class="my-0 h6"><a href="?uid=CUMV-COMPRE-SANJUANINO" target="_self" class="stretched-link">Régimen Compre Sanjuaninos - Accedé al
                beneficio</a></h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container subsection">
      <h4 class="subtitle">Modificación y/o actualización de datos</h4>
      <div class="container mt-4">
        <a href="https://compraspublicas.sanjuan.gob.ar/pdf/Inscripción_RUPE_02.pdf"
          target="_blank" class="text-ptn-color-gsj-brand">
          <h5>Trámite Presencial - Acceder al instructivo</h5>
        </a>
        <h5 class="text-ptn-color-gsj-brand"> <a
            href="https://compraspublicas.sanjuan.gob.ar/pdf/Tramite_no_presencial.pdf"
            target="_blank" class="text-ptn-color-gsj-brand"> Trámite No Presencial - Acceder al instructivo </a></h5>
      </div>
      <div class="row justify-content-center row-cols-1 g-4 mt-2">
        <div class="col">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                  </path>
                </svg><!--v-if-->
              </div><!--v-if-->
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/02-modificacion_proveedor_persona_humana_4.0.pdf"
                  target="_blank" class="stretched-link">Formulario de Modificación Proveedor Persona Humana</a></h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                  </path>
                </svg><!--v-if-->
              </div><!--v-if-->
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a
                  href="https://compraspublicas.sanjuan.gob.ar/pdf/04-modificacion_proveedor_persona_juridica_4.1_Digital.pdf"
                  target="_blank" class="stretched-link">Formulario de Modificación Proveedor Persona Jurídica</a></h3>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="container subsection px-3">
        <h5 class="text-ptn-color-gsj-brand pl-3">Trámite alta o modificación rubro comercial</h5>
        <p>
          El rubro a dar de alta debe figurar en la constancia de AFIP, inscripción sobre los Ingresos Brutos de la
          <b>Dirección General de Rentas de la Provincia de San Juan </b> o en <b> Convenio Multilateral</b> y
          corresponderse con lo establecido en el <b>Objeto Social</b>, en el caso de personas jurídicas.
        </p>
      <h5 class="subtitle">Paso a paso:</h5>
      <p>
        <b class="text-ptn-color-gsj-brand">1-</b> Para el alta o la
        modificación del rubro, podes solicitarla a través de correo electrónico
        indicando en el cuerpo del mail los siguientes datos:
      </p>
      <ul>
        <li>
          En Asunto del mail indicar lo que solicita: Alta o Modificación de
          rubro
        </li>
        <li>Nombre y apellido o razón social del proveedor.</li>
        <li>CUIT.</li>
        <li>
          Colocar ALTA/MODIFICACION de rubro, según lo que realice, indicando el
          código de actividad según nomenclador de AFIP y la actividad, ejemplo:
          <br />
          Alta - Activ. Cod. 7.09.00 - Servicios Profesionales.
        </li>
      </ul>
      <p>
        <b class="text-ptn-color-gsj-brand">2-</b> Adjuntar en archivo pdf, la
        documentación de respaldo según corresponda: constancia de AFIP,
        Constancia de DGR y objeto social.
      </p>
      <p>
        <b class="text-ptn-color-gsj-brand">3-</b> Una vez detallado los datos
        solicitados enviar mail al RUPE rupesj.proveedores@sanjuan.gob.ar y a la
        brevedad se le contestara confirmando el alta o modificación.
      </p>
    </div>
    <div class="container ">
      <div class="row justify-content-center row-cols-1 g-4 ">
        <div class="col ">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
                  <path
                  d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z">
                  </path>
                </svg>
              </div>
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a href="mailto:rupesj.proveedores@sanjuan.gob.ar" target="_blank" class="stretched-link">MODIFICACIÓN O
                  ALTA DE RUBRO COMERCIAL</a>
                </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- SOY PROVEEDOR FIN -->
</template>

<style>
.subtitle {
  color: #6b6b6b;
}
.subsection{
  padding: 30px 0;
}
</style>