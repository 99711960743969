<template>
    <section class="bg-light">
        <div class="container">
            <div class="text-center">
                <h2 class="pb-3">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            </div>
            <div class="row">
                <div v-bind:class="[this.items && this.items.length > 1 ? 'col-lg-3 col-md-4 col-sm-6' : 'col-md-12']"  v-for="(el, index) in this.items" :key="index">
                    <div class="row">
                        <div class="" v-bind:class="[this.items.length > 1 ? 'col-md-12 p-3' : 'col-lg-3 offset-lg-2  text-center']">
                            <img v-bind:src="el.imagen ? el.imagen : el.urlImagen"
                                v-bind:class="[this.items.length > 1 ? 'multi-border' : '']"
                                class="img-fluid rounded mb-3 mb-lg-0" v-bind:alt="el.title" width="600" height="600"
                                loading="lazy">
                        </div>
                        <div class="text-center" v-bind:class="[this.items.length > 1 ? 'col-md-12' : 'col-lg-5 text-lg-start']">
                            <div v-bind:class="[this.items.length > 1 ? 'h4' : 'h2']">{{ el.title }}</div>
                            <div v-bind:class="[this.items.length > 1 ? 'link-secondary text-ptn-color-gsj-2' : 'h5 link-secondary text-ptn-color-gsj-2']">{{ el.subtitle }}</div>
                            <p v-if="this.items.length == 1" v-html="el.desc ? el.desc : ''"></p>
                            <a v-if="showProtocolo && this.items.length == 1"
                                class="btn btn-outline-ptn-color-gsj-brand px-4 rounded-pill border border-2 border-ptn-color-gsj-brand"
                                href="https://sanjuan.gob.ar/docs/protocolo.pdf">Protocolo provincial</a>

                                <a v-if="this.items.length > 1 && el.url"
                                class="btn btn-outline-secondary px-4 rounded-pill border border-2 border-secondary m-3"
                                v-bind:href="el.url">Sitio Web</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-5" v-if="showLinks && this.links ">
            <div class="row row-cols-1 row-cols-md-3 g-3 text-center">

                <div class="mb-2" v-for="(el, index) in this.links" :key="index">
                    <a class="d-block pe-lg-4 lh-sm" v-bind:href="el.url ? el.url : '#'">
                        <h3 class="mb-0 fs-6">{{ el.title }}</h3>
                    </a>
                </div>

            </div>
        </div>
    </section>
</template>


<script>
const axios = require("axios");
export default {
    name: "AutorityComponent",
    props: {
        titulo: String,
        cargo: String,
        data: Object,
        showLinks: {
            type: Boolean,
            default: true
        },
        showProtocolo: {
            type: Boolean,
            default: true
        },
        urlImagen: String,
        filtros: Object
    },
    data() {
        return {
            hash: "ATC",
            items: null,
            title: null,
            links: null,
        };
    },
    mounted() {
        if (this.data == null) {
            let params = {};
            if (this.filtros != null && this.filtros != "") {
                params = this.filtros;
            }
            params.tipo = this.hash;
            axios.post(process.env.VUE_APP_API_URL, params)
                .then(response => {
                    this.items = response.data ? response.data.items : null
                    this.links = response.data && response.data.items && response.data.items.length == 1 ? response.data.items[0].links : null;
                    response.links = this.links;
                    this.title = response.data ? response.data.title : null
                    localStorage.setItem(this.hash, JSON.stringify(response));
                })
                .catch(error => {
                    if (!error.response) {
                        console.log("Fuera de Linea");
                        if (localStorage.getItem(this.hash)) {
                            console.log("Cargando elementos de cache para " + this.hash);
                            var resp = JSON.parse(localStorage.getItem(this.hash));
                            this.items = resp.data ? resp.data.items : null
                            this.links = resp.data ? resp.data.links : null
                            this.title = resp.data ? resp.data.title : null
                        }
                    }
                })
        } else {
            this.items = this.data;
        }
    },
}
</script>